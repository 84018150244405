import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Page from "./components/Page";
import Header from "./components/section/Header";
import Footer from "./components/section/Footer";
import WhoUsesUsCard from "./components/common/WhoUsesUsCard";
import DataProductCard from "./components/common/DataProductCard";
import PricingPlan from "./components/common/PricingPlan";
import FAQ from "./components/common/FAQ";
import FormSection from "./components/form/FormSection";
import axios from "./api/axios";
import useSWR from "swr";

function App({ initialData }) {
	const [stories, setStories] = useState(initialData);

	const redirectStory = stories?.find((story) => story?.name === "Redirects");
	const requestUrl = window.location.pathname + window.location.search;

	const fetcher = (url) => axios(url).then((r) => r);
	const {
		data: loadedData,
		error,
		isLoading
	} = useSWR("/api/getStories", fetcher);

	useEffect(() => {
		if (loadedData) {
			setStories(loadedData.data);
		}
	}, [loadedData]);

	if (isLoading) {
		return;
	}

	if (error) {
		return <p>Error fetching data: {error.message}</p>;
	}

	return (
		<BrowserRouter future={{ v7_startTransition: true }}>
			<Routes>
				<Route
					path="/"
					element={
						<Page
							story={stories?.find(
								(story) => story?.name === "Home"
							)}
						/>
					}
				/>
				{stories &&
					stories.length > 0 &&
					stories?.map((story) => {
						if (story?.content?.component === "whoUsesUsCard") {
							return (
								<Route
									key={story?.full_slug}
									path={`/${story?.full_slug}`}
									element={<WhoUsesUsCard story={story} />}
								/>
							);
						}

						if (story?.content?.component === "dataProductCard") {
							return (
								<Route
									key={story?.full_slug}
									path={`/${story?.full_slug}`}
									element={<DataProductCard story={story} />}
								/>
							);
						}

						if (story?.content?.component === "pricingPlan") {
							return (
								<Route
									key={story?.full_slug}
									path={`/${story?.full_slug}`}
									element={<PricingPlan story={story} />}
								/>
							);
						}

						if (story?.content?.component === "faq") {
							return (
								<Route
									key={story?.full_slug}
									path={`/${story?.full_slug}`}
									element={<FAQ story={story} />}
								/>
							);
						}

						if (story?.content?.component === "formSection") {
							return (
								<Route
									key={story?.full_slug}
									path={`/${story?.full_slug}`}
									element={<FormSection story={story} />}
								/>
							);
						}

						if (story?.content?.component === "header") {
							return (
								<Route
									key={story?.full_slug}
									path={`/${story?.full_slug}`}
									element={<Header story={story} />}
								/>
							);
						}

						if (story?.content?.component === "footer") {
							return (
								<Route
									key={story?.full_slug}
									path={`/${story?.full_slug}`}
									element={<Footer story={story} />}
								/>
							);
						}

						return (
							<Route
								key={story?.full_slug}
								path={`/${story?.full_slug}`}
								element={<Page story={story} />}
							/>
						);
					})}

				{typeof window !== "undefined" &&
					redirectStory &&
					redirectStory.content.redirect_entries.length > 0 &&
					redirectStory.content.redirect_entries?.map((redirect) => {
						if (redirect.source_url === requestUrl) {
							return (
								<Route
									path={window.location.pathname}
									element={
										<Navigate
											to={`/${redirect.target_story.full_slug}`}
											replace
										/>
									}
								/>
							);
						}
					})}

				<Route
					path="/DataContentSolutions.aspx"
					element={<Navigate to="/" replace />}
				/>

				<Route
					path="/DataFactory.aspx"
					element={<Navigate to="/" replace />}
				/>

				<Route
					path="/DataFiedAPI.aspx"
					element={<Navigate to="/" replace />}
				/>

				<Route
					path="/Default.aspx"
					element={<Navigate to="/" replace />}
				/>

				<Route
					path="/default.aspx"
					element={<Navigate to="/" replace />}
				/>

				<Route
					path="/CustomerSupport.aspx"
					element={<Navigate to="/contact-us" replace />}
				/>

				<Route
					path="/ContactSales.aspx"
					element={<Navigate to="/contact-us" replace />}
				/>

				<Route
					path="/location.aspx"
					element={<Navigate to="/contact-us" replace />}
				/>

				<Route
					path="/MediaContact.aspx"
					element={<Navigate to="/contact-us" replace />}
				/>

				<Route
					path="/Customers.aspx"
					element={<Navigate to="/company/who-uses-us" replace />}
				/>

				<Route
					path="/overview.aspx"
					element={<Navigate to="/company/about-us" replace />}
				/>

				<Route
					path="/Privacy.aspx"
					element={<Navigate to="/privacy-statement" replace />}
				/>

				<Route
					path="/Terms.aspx"
					element={<Navigate to="/terms" replace />}
				/>

				<Route
					path="*"
					element={
						<Page
							story={stories?.find(
								(story) => story?.name === "Error 404"
							)}
						/>
					}
				/>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
