import { Box, Text } from "@chakra-ui/react";
import { renderRichText, storyblokEditable } from "@storyblok/react";

const TimelineItem = ({ blok }) => {
	const { text } = blok;
	return (
		<Box {...storyblokEditable(blok)} textAlign="center">
			{text && (
				<Text
					dangerouslySetInnerHTML={{ __html: renderRichText(text) }}
				/>
			)}
		</Box>
	);
};

export default TimelineItem;
