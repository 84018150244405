import { Box, Container, Flex, Grid, GridItem } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { useRef } from "react";

const FigureSection = ({ blok }) => {
	const { figures } = blok;

	const countUpRef = useRef(null);

	return (
		<Flex
			{...storyblokEditable(blok)}
			py={{ base: 10, lg: 20 }}
			flexDirection="column"
			justify="center"
			align="center"
		>
			<Container maxW={{ base: "sm", lg: "7xl" }} mx="auto">
				<Grid
					display={{ base: "none", lg: "grid" }}
					templateColumns={`repeat(${figures.length}, 1fr)`}
					gap={5}
					w="100%"
				>
					{figures &&
						figures.length > 0 &&
						figures.map((blok, idx) => (
							<GridItem key={blok._uid} w="100%">
								<StoryblokComponent
									showVerticalBars={
										idx !== 0 && idx !== figures.length
									}
									blok={blok}
								/>
							</GridItem>
						))}
				</Grid>

				<Box display={{ base: "block", lg: "none" }}>
					<Swiper
						modules={[Navigation]}
						spaceBetween={50}
						slidesPerView={1}
						navigation
						onSwiper={(swiper) => console.log(swiper)}
						onSlideChange={() => console.log("slide change")}
					>
						{figures &&
							figures.length > 0 &&
							figures.map((blok, idx) => (
								<SwiperSlide key={blok._uid}>
									<StoryblokComponent
										showVerticalBars={false}
										blok={blok}
										countUpRef={countUpRef}
									/>
								</SwiperSlide>
							))}
					</Swiper>
				</Box>

				<style>{`
					.swiper-button-prev {
						color: #007091;
					}

					.swiper-button-next {
						color: #007091;
					}
				`}</style>
			</Container>
		</Flex>
	);
};

export default FigureSection;
