import { Flex, Image, Text, Link } from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import { renderRichText, storyblokEditable } from "@storyblok/react";

const StoryblokLink = ({ blok, styles }) => {
	const { label, link, image, isExternal, target } = blok;

	return (
		<Flex {...storyblokEditable(blok)} align="center" {...styles}>
			{(label || link) && (
				<Link
					as={ReactLink}
					target={target}
					to={`${!isExternal ? "/" : ""}${
						link.cached_url || link.url
					}`}
				>
					<Flex justify="center" align="center">
						{image?.filename && (
							<Image
								src={image.filename}
								alt={image.alt}
								mr={4}
								w={styles?.imagewidth || "100%"}
								h={styles?.imageheight || "100%"}
								transition={styles?.transition}
								transform={styles?.transform}
								transformOrigin={styles?.transformOrigin}
							/>
						)}

						<Text
							dangerouslySetInnerHTML={{
								__html: renderRichText(label)
							}}
						/>
					</Flex>
				</Link>
			)}
		</Flex>
	);
};

export default StoryblokLink;
