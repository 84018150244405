import { Container, Flex, Text } from "@chakra-ui/react";
import {
	StoryblokComponent,
	renderRichText,
	storyblokEditable
} from "@storyblok/react";

const TableSection = ({ blok }) => {
	const { textPosition, heading, text, tables, backgroundColor } = blok;

	return (
		<Flex
			{...storyblokEditable(blok)}
			bg={backgroundColor}
			pb={{ base: 10, lg: 20 }}
			flexDirection="column"
			justify="center"
			align="center"
		>
			<Container maxW={{ base: "sm", lg: "6xl" }} mx="auto">
				{heading && (
					<Text
						mb={12}
						textAlign="center"
						dangerouslySetInnerHTML={{
							__html: renderRichText(heading)
						}}
					/>
				)}

				{text && (
					<Text
						mb={12}
						textAlign={textPosition}
						dangerouslySetInnerHTML={{
							__html: renderRichText(text)
						}}
					/>
				)}

				{tables &&
					tables.length > 0 &&
					tables.map((blok) => (
						<StoryblokComponent key={blok._uid} blok={blok} />
					))}
			</Container>
		</Flex>
	);
};

export default TableSection;
