import { Flex, FormLabel } from "@chakra-ui/react";
import { CheckboxField, Field, SelectField } from "@saas-ui/react";

const FormInput = ({ blok, isPricingFormOpen }) => {
	const { label, name, placeholder, type, options, isRequired, multiSelect } =
		blok;

	const optionsData = options.map((option) => {
		return { value: option, label: option };
	});

	return (
		<>
			{type === "select" ? (
				<>
					{label && (
						<FormLabel color="primary.teal" fontWeight="black">
							{label + (isRequired ? " *" : "")}
						</FormLabel>
					)}

					<SelectField
						name={name}
						placeholder={placeholder}
						options={optionsData}
						isRequired={isRequired}
						multiple={multiSelect}
					/>
				</>
			) : type === "checkbox" ? (
				<>
					{label && (
						<FormLabel color="primary.teal" fontWeight="black">
							{label + (isRequired ? " *" : "")}
						</FormLabel>
					)}

					<Flex>
						{options &&
							options.length > 0 &&
							options.map((option) => {
								return (
									<CheckboxField
										key={option}
										name={name}
										value={option}
										label={option}
										colorScheme="teal"
										defaultChecked={
											isPricingFormOpen === option
										}
										isRequired={isRequired}
									/>
								);
							})}
					</Flex>
				</>
			) : (
				<>
					{label && (
						<FormLabel color="primary.teal" fontWeight="black">
							{label + (isRequired ? " *" : "")}
						</FormLabel>
					)}
					<Field
						name={name}
						type={type}
						placeholder={placeholder}
						bg="white"
						color="black"
						isRequired={isRequired}
						rows={8}
					/>
				</>
			)}
		</>
	);
};

export default FormInput;
