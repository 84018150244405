import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	Heading,
	Image,
	Text
} from "@chakra-ui/react";
import { Card, CardBody } from "@saas-ui/react";
import {
	renderRichText,
	storyblokEditable,
	useStoryblokState
} from "@storyblok/react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const DataProductCard = ({ story: initialStory, blok }) => {
	const story = useStoryblokState(initialStory);
	const { link, heading, text, images } = story?.content || blok;

	const navigate = useNavigate();

	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	const handleMouseEnter = () => {
		setCurrentImageIndex(1);
	};

	const handleMouseLeave = () => {
		setCurrentImageIndex(0);
	};

	const handleClick = () => {
		if (link?.cached_url) {
			navigate(`/${link.cached_url}`);
		} else {
			navigate(`/${link?.url}`);
		}
	};

	return (
		<>
			<Card
				display={{ base: "none", lg: "flex" }}
				{...storyblokEditable(blok)}
				p={2}
				h="100%"
				border="1px solid tertiary.teal"
				borderRadius="lg"
				onClick={handleClick}
				cursor="pointer"
				_hover={{
					color: "white",
					bg: "primary.teal"
				}}
				transition="color 0.4s ease-in-out, background-color 0.4s ease-in-out"
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<CardBody>
					<Flex flexDirection="column" justify="center">
						{images[currentImageIndex]?.filename && (
							<Box>
								<Image
									src={images[currentImageIndex].filename}
									alt={images[currentImageIndex].alt}
									mb={10}
								/>
							</Box>
						)}

						{heading && (
							<Heading as="h3" mb={4}>
								{heading}
							</Heading>
						)}

						{text && (
							<Text
								dangerouslySetInnerHTML={{
									__html: renderRichText(text)
								}}
							/>
						)}
					</Flex>
				</CardBody>
			</Card>
			<Accordion
				allowToggle
				display={{ base: "block", lg: "none" }}
				boxShadow="lg"
			>
				<AccordionItem border="none">
					{({ isExpanded }) => (
						<Box
							rounded="lg"
							bg={isExpanded ? "primary.teal" : "white"}
							color={isExpanded ? "white" : "black"}
							boxShadow={isExpanded ? "lg" : "none"}
						>
							<AccordionButton
								display="flex"
								justifyContent="space-between"
								_hover={{
									bg: "none"
								}}
							>
								<Flex align="center">
									{images[isExpanded ? 1 : 0]?.filename && (
										<Box mr={4}>
											<Image
												src={
													images[isExpanded ? 1 : 0]
														.filename
												}
												alt={
													images[isExpanded ? 1 : 0]
														.alt
												}
											/>
										</Box>
									)}
									{heading && (
										<Heading
											fontSize="2xl"
											textAlign="left"
										>
											{heading}
										</Heading>
									)}
								</Flex>

								<Flex
									bg="white"
									p={3}
									w={8}
									h={8}
									justify="center"
									align="center"
									rounded="full"
									border={!isExpanded && "1px solid black"}
								>
									{isExpanded ? (
										<MinusIcon color="black" />
									) : (
										<AddIcon />
									)}
								</Flex>
							</AccordionButton>

							<AccordionPanel>
								<Text
									dangerouslySetInnerHTML={{
										__html: renderRichText(text)
									}}
								/>
								{link && (
									<Link
										to={`/${link.cached_url || link.url}`}
									>
										<Text textDecor="underline" mt={2}>
											Read More
										</Text>
									</Link>
								)}
							</AccordionPanel>
						</Box>
					)}
				</AccordionItem>
			</Accordion>
		</>
	);
};

export default DataProductCard;
