import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	Text
} from "@chakra-ui/react";
import {
	renderRichText,
	storyblokEditable,
	useStoryblokState
} from "@storyblok/react";

const FAQ = ({ story: initialStory, blok }) => {
	const story = useStoryblokState(initialStory);
	const { question, answer } = story?.content || blok;

	return (
		<AccordionItem
			{...storyblokEditable(blok)}
			border="1px solid secondary.ink"
			rounded="xl"
			mb={7}
		>
			{({ isExpanded }) => (
				<Box
					rounded="xl"
					bg={isExpanded ? "primary.teal" : "white"}
					color={isExpanded ? "white" : "black"}
					p={2}
					boxShadow={isExpanded ? "lg" : "none"}
				>
					<AccordionButton
						_hover={{
							bg: "none"
						}}
					>
						{question && (
							<Text
								as="span"
								flex="1"
								textAlign="left"
								fontSize="xl"
								fontWeight="bold"
								dangerouslySetInnerHTML={{
									__html: renderRichText(question)
								}}
							/>
						)}

						<Flex
							bg="white"
							p={3}
							w={10}
							h={10}
							justify="center"
							align="center"
							rounded="full"
							border={!isExpanded && "1px solid black"}
						>
							{isExpanded ? (
								<MinusIcon color="black" />
							) : (
								<AddIcon />
							)}
						</Flex>
					</AccordionButton>

					{answer && (
						<AccordionPanel
							pb={4}
							textAlign="left"
							sx={{
								"& ol": {
									marginLeft: "1em"
								}
							}}
							dangerouslySetInnerHTML={{
								__html: renderRichText(answer)
							}}
						/>
					)}
				</Box>
			)}
		</AccordionItem>
	);
};

export default FAQ;
