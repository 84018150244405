import {
	Box,
	Container,
	Flex,
	Grid,
	GridItem,
	Image,
	Text
} from "@chakra-ui/react";
import {
	storyblokEditable,
	renderRichText,
	StoryblokComponent
} from "@storyblok/react";

const HeroSection = ({ blok }) => {
	const { text, ctas, image, imagePosition } = blok;

	return (
		<Flex
			{...storyblokEditable(blok)}
			pt={{ base: 12, lg: 0 }}
			pb={12}
			flexDirection="column"
			justify="center"
			align="center"
			position="relative"
			backgroundImage={{ base: image.filename, lg: "none" }}
			backgroundSize="cover"
			backgroundPosition="center"
		>
			<Box
				display={{ base: "block", lg: "none" }}
				position="absolute"
				top={0}
				left={0}
				w="100%"
				h="100%"
				backgroundColor="rgba(245, 245, 245, 0.9)"
			/>

			<Container
				maxW={{ base: "sm", lg: "7xl" }}
				mx="auto"
				position="relative"
			>
				<Grid
					templateColumns="repeat(9,1fr)"
					gap={8}
					alignItems="center"
				>
					<GridItem
						colSpan={{ base: 9, lg: 5 }}
						order={imagePosition === "left" && 2}
					>
						{text && (
							<Text
								mb={10}
								dangerouslySetInnerHTML={{
									__html: renderRichText(text)
								}}
							/>
						)}

						<Flex mb={2}>
							{ctas &&
								ctas.length > 0 &&
								ctas.map((cta) => (
									<Box key={cta._uid} mr={4}>
										<StoryblokComponent blok={cta} />
									</Box>
								))}
						</Flex>
					</GridItem>

					<GridItem
						display={{ base: "none", lg: "block" }}
						colSpan={4}
						order={imagePosition === "left" && 1}
					>
						{image.filename && (
							<Image
								src={image.filename}
								alt={image.alt}
								mb={-24}
							/>
						)}
					</GridItem>
				</Grid>
			</Container>
		</Flex>
	);
};

export default HeroSection;
