import React, { useEffect } from "react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

const Page = ({ story }) => {
	const { content: blok } = story || {};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{blok?.title || ""}</title>
				<meta name="description" content={blok?.description || ""} />
			</Helmet>

			<main {...storyblokEditable(blok)}>
				{blok?.header ? (
					<StoryblokComponent
						key={blok?.header.content._uid}
						blok={blok?.header.content}
					/>
				) : null}
				{blok?.body
					? blok?.body.map((blok) => (
							<StoryblokComponent key={blok._uid} blok={blok} />
					  ))
					: null}
				{blok?.footer ? (
					<StoryblokComponent
						key={blok?.footer.content._uid}
						blok={blok?.footer.content}
						showTopFooter={blok?.showTopFooter}
					/>
				) : null}
			</main>
			<ScrollToTop />
		</>
	);
};

export default Page;
