import {
	ChevronDownIcon,
	ChevronUpIcon,
	HamburgerIcon,
	MinusIcon
} from "@chakra-ui/icons";
import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Container,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text
} from "@chakra-ui/react";
import { Button, Divider } from "@saas-ui/react";
import {
	StoryblokComponent,
	renderRichText,
	storyblokEditable,
	useStoryblokState
} from "@storyblok/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Header = ({ story: initialStory, blok }) => {
	const story = useStoryblokState(initialStory);
	const { topHeader, image, links, buttons } = story?.content || blok;

	const navigate = useNavigate();
	const location = useLocation();
	const isHomepage =
		location.pathname === "/" || location.pathname === "/home";

	const [index, setIndex] = useState([]);

	const [isSticky, setIsSticky] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			if (scrollPosition >= 50 && window.innerWidth <= 992) {
				setIsSticky(true);
			}
			if (scrollPosition === 0) {
				setIsSticky(false);
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<Accordion
			position={{
				base: "sticky",
				lg: "relative"
			}}
			top={0}
			zIndex={2}
			allowMultiple
			index={index}
			onChange={setIndex}
			boxShadow={{ base: "xl", lg: "none" }}
		>
			<AccordionItem border="none">
				{({ isExpanded }) => (
					<>
						<Flex
							{...storyblokEditable(blok)}
							pt={isSticky ? 2 : 8}
							pb={
								isHomepage
									? { base: isSticky ? 2 : 8, lg: 16 }
									: 4
							}
							flexDirection="column"
							justify="center"
							align="center"
							bg={{ base: "white", lg: "transparent" }}
						>
							<Container
								maxW={{ base: "sm", lg: "7xl" }}
								mx="auto"
							>
								<Flex
									display={{ base: "none", lg: "flex" }}
									justify="end"
								>
									{topHeader &&
										topHeader.length > 0 &&
										topHeader.map((link) => (
											<Box key={link._uid} ml={12}>
												<StoryblokComponent
													blok={link}
													styles={{
														imagewidth: 30,
														imageheight: 30
													}}
												/>
											</Box>
										))}
								</Flex>

								<Flex align="center" justify="space-between">
									{image &&
										image.length > 0 &&
										image.map((item) => (
											<StoryblokComponent
												key={item._uid}
												blok={item}
												styles={{
													transform: isSticky
														? "scale(0.8)"
														: "scale(1)",

													transition:
														"transform 0.3s ease",
													transformOrigin: "left"
												}}
											/>
										))}

									<AccordionButton
										display={{
											base: "block",
											lg: "none"
										}}
										w="fit-content"
										_hover={{
											bg: "none"
										}}
									>
										{isExpanded ? (
											<Flex
												bg="white"
												p={3}
												w={8}
												h={8}
												justify="center"
												align="center"
												rounded="full"
												border="1px solid black"
											>
												<MinusIcon color="black" />
											</Flex>
										) : (
											<HamburgerIcon
												boxSize={6}
												color="primary.teal"
											/>
										)}
									</AccordionButton>

									<Flex
										display={{
											base: "none",
											lg: "flex"
										}}
										align="center"
									>
										<Flex
											align="center"
											fontSize="md"
											color="gray.500"
										>
											{links &&
												links.length > 0 &&
												links.map((link) => (
													<Box
														key={link._uid}
														mr={10}
													>
														{link.subLinks &&
														link.subLinks.length >
															0 ? (
															<Menu isLazy>
																<MenuButton
																	as={Button}
																	rightIcon={
																		<ChevronDownIcon
																			boxSize={
																				6
																			}
																		/>
																	}
																>
																	<StoryblokComponent
																		blok={
																			link
																		}
																	/>
																</MenuButton>
																<MenuList>
																	{link.subLinks.map(
																		(
																			subLink
																		) => (
																			<MenuItem
																				key={
																					subLink._uid
																				}
																				onClick={() => {
																					navigate(
																						`/${
																							subLink
																								?.link
																								?.cached_url ||
																							subLink
																								?.link
																								?.url
																						}`
																					);
																				}}
																			>
																				<StoryblokComponent
																					blok={
																						subLink
																					}
																				/>
																			</MenuItem>
																		)
																	)}
																</MenuList>
															</Menu>
														) : (
															<StoryblokComponent
																blok={link}
															/>
														)}
													</Box>
												))}
										</Flex>

										<Flex>
											{buttons &&
												buttons.length > 0 &&
												buttons
													.filter(
														(button) =>
															button.text !==
															"Edgar Pro Login"
													)
													.map((button) => (
														<Box key={button._uid}>
															<StoryblokComponent
																blok={button}
															/>
														</Box>
													))}
										</Flex>
									</Flex>
								</Flex>
							</Container>

							{isHomepage && (
								<Divider
									display={{ base: "none", lg: "block" }}
									mt={4}
									h={0.15}
									bg="rgba(0, 0, 0, 0.15);"
								/>
							)}
						</Flex>
						<AccordionPanel
							bg="primary.teal"
							color="white"
							fontWeight="bold"
							pt={8}
						>
							<Container maxW="sm" mx="auto">
								{links &&
									links.length > 0 &&
									links.map((link) => (
										<Box key={link._uid}>
											{link.subLinks &&
											link.subLinks.length > 0 ? (
												<Accordion allowToggle>
													<AccordionItem border="none">
														{({ isExpanded }) => (
															<>
																<AccordionButton
																	_hover={{
																		bg: "none"
																	}}
																	p={0}
																	mb={4}
																>
																	<Text
																		fontWeight="bold"
																		fontSize="medium"
																		dangerouslySetInnerHTML={{
																			__html: renderRichText(
																				link?.label
																			)
																		}}
																	/>

																	{isExpanded ? (
																		<ChevronUpIcon
																			boxSize={
																				6
																			}
																		/>
																	) : (
																		<ChevronDownIcon
																			boxSize={
																				6
																			}
																		/>
																	)}
																</AccordionButton>
																<AccordionPanel
																	onClick={() =>
																		setIndex(
																			[]
																		)
																	}
																>
																	{link.subLinks.map(
																		(
																			subLink
																		) => (
																			<Box
																				key={
																					subLink._uid
																				}
																				onClick={() => {
																					navigate(
																						`/${
																							subLink
																								?.link
																								?.cached_url ||
																							subLink
																								?.link
																								?.url
																						}`
																					);
																				}}
																				mb={
																					4
																				}
																			>
																				<StoryblokComponent
																					blok={
																						subLink
																					}
																				/>
																			</Box>
																		)
																	)}
																</AccordionPanel>
															</>
														)}
													</AccordionItem>
												</Accordion>
											) : (
												<Box
													mb={4}
													onClick={() => {
														navigate(
															`/${
																link?.link
																	?.cached_url ||
																link?.link?.url
															}`
														);
														setIndex([]);
													}}
												>
													<StoryblokComponent
														blok={link}
													/>
												</Box>
											)}
										</Box>
									))}
								{buttons &&
									buttons.length > 0 &&
									buttons.map((button) => (
										<Box
											cursor="pointer"
											key={button._uid}
											mb={4}
											onClick={() => {
												setIndex([]);
												if (button?.isExternal)
													window.open(
														button?.link
															?.cached_url ||
															button?.link?.url,
														"_blank"
													);
												else
													navigate(
														`/${
															button?.link
																?.cached_url ||
															button?.link?.url
														}`
													);
											}}
										>
											<Text>{button?.text}</Text>
										</Box>
									))}
							</Container>
						</AccordionPanel>
					</>
				)}
			</AccordionItem>
		</Accordion>
	);
};

export default Header;
