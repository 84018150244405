import {
	Box,
	Container,
	Flex,
	Grid,
	GridItem,
	Image,
	Text
} from "@chakra-ui/react";
import { Card, CardBody } from "@saas-ui/react";
import {
	storyblokEditable,
	renderRichText,
	StoryblokComponent
} from "@storyblok/react";
import { useLocation } from "react-router-dom";

const TextWithImageSection = ({ blok }) => {
	const {
		heading,
		text,
		ctas,
		links,
		form,
		image,
		imagePosition,
		backgroundColor,
		paddingTop,
		paddingBottom
	} = blok;

	const location = useLocation();

	return (
		<Flex
			{...storyblokEditable(blok)}
			bg={backgroundColor}
			pt={{ base: paddingTop / 2, lg: paddingTop }}
			pb={{ base: paddingBottom / 2, lg: paddingBottom }}
			flexDirection="column"
			justify="center"
			align="center"
		>
			<Container maxW={{ base: "sm", lg: "7xl" }} mx="auto">
				<Grid
					templateColumns={{
						base: "repeat(1,1fr)",
						lg: "repeat(2,1fr)"
					}}
					gap={14}
					alignItems="center"
				>
					<GridItem
						order={{ base: 2, lg: imagePosition === "left" && 2 }}
					>
						{heading && (
							<Text
								mb={4}
								textAlign={{ base: "center", lg: "left" }}
								dangerouslySetInnerHTML={{
									__html: renderRichText(heading)
								}}
							/>
						)}

						{text && (
							<Text
								mb={8}
								dangerouslySetInnerHTML={{
									__html: renderRichText(text)
								}}
							/>
						)}

						<Flex
							justify={{ base: "center", lg: "flex-start" }}
							align="center"
							mb={4}
						>
							{ctas &&
								ctas.length > 0 &&
								ctas.map((cta, idx) => (
									<Box
										key={cta._uid}
										mr={{
											base: ctas?.length > 1 ? 8 : 0,
											lg: 10
										}}
									>
										<StoryblokComponent
											blok={cta}
											autoScroll={
												// cta?.text === "Access Data"
												idx === 1
											}
											pathname={location.pathname}
										/>
									</Box>
								))}
						</Flex>

						<Flex
							justify={{ base: "center", lg: "flex-start" }}
							align="center"
							mb={4}
						>
							{links &&
								links.length > 0 &&
								links.map((link) => (
									<StoryblokComponent
										blok={link}
										key={link._uid}
									/>
								))}
						</Flex>

						{form && form.length > 0 && (
							<Card
								borderWidth="1px"
								borderStyle="solid"
								borderColor="primary.teal"
								p={3}
							>
								<CardBody>
									<StoryblokComponent blok={form[0]} />
								</CardBody>
							</Card>
						)}
					</GridItem>

					<GridItem
						order={{
							base: 1,
							lg: imagePosition === "left" ? 1 : 2
						}}
					>
						{image?.filename && (
							<Image src={image.filename} alt={image.alt} />
						)}
					</GridItem>
				</Grid>
			</Container>
		</Flex>
	);
};

export default TextWithImageSection;
