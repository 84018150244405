import { Flex, Image } from "@chakra-ui/react";
import { Button } from "@saas-ui/react";
import { storyblokEditable } from "@storyblok/react";
import { useNavigate } from "react-router-dom";

const StoryblokButton = ({
	blok,
	styles,
	colorScheme = "teal",
	onClick,
	autoScroll,
	pathname
}) => {
	const { text, type, variant, size, image, link } = blok;

	const navigate = useNavigate();

	const handleClick = () => {
		navigate(`/${link.cached_url || link.url}`, {
			state: { autoScroll, pathname }
		});
	};

	return (
		<Flex {...storyblokEditable(blok)} align="center" {...styles}>
			<Button
				id={text}
				leftIcon={
					image?.filename && (
						<Image
							src={image.filename}
							alt={image.alt}
							w={22}
							h={22}
						/>
					)
				}
				type={type}
				variant={variant}
				size={size}
				onClick={() => {
					if (link?.cached_url || link?.url) handleClick();
					if (onClick) onClick();
				}}
				colorScheme={colorScheme}
			>
				{text}
			</Button>
		</Flex>
	);
};

export default StoryblokButton;
