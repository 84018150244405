import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Divider } from "@saas-ui/react";
import { storyblokEditable } from "@storyblok/react";

import CountUp from "react-countup";

const extractNumber = (str) => {
	const num = str.match(/[+-]?\d+(\.\d+)?/g);
	return num ? Number(num.join("")) : 0;
};

const hasDecimalPoints = (num) => {
	return !Number.isInteger(Number(num));
};

const extractString = (str) => {
	const string = str.match(/[a-zA-Z]+/g);
	return string ? string.join("") : "";
};

const Figure = ({ blok, showVerticalBars, countUpRef }) => {
	const { figure, description, image } = blok;

	return (
		<Flex
			{...storyblokEditable(blok)}
			flexDirection="column"
			justify="space-between"
			align="center"
			minH={255}
		>
			{image.filename && <Image src={image.filename} alt={image.alt} />}

			<Box textAlign="center" position="relative" w="100%">
				{figure && (
					<Text fontSize="5xl" color="primary.teal">
						<CountUp
							end={extractNumber(figure)}
							separator=","
							duration={3}
							enableScrollSpy
							scrollSpyOnce
							decimal="."
							decimals={
								hasDecimalPoints(extractNumber(figure)) ? 1 : 0
							}
						/>
						{extractString(figure).toUpperCase()}
					</Text>
				)}

				{description && <Text position="relative">{description}</Text>}

				{showVerticalBars && (
					<Divider
						position="absolute"
						h="65%"
						borderLeft="1px solid #C6C6C6"
						orientation="vertical"
						bottom={0}
					/>
				)}
			</Box>
		</Flex>
	);
};

export default Figure;
