import { defineStyleConfig } from "@chakra-ui/react";

export const buttonTheme = defineStyleConfig({
	baseStyle: {
		borderRadius: "3xl"
	},
	sizes: {
		md: {
			px: 12,
			h: 12
		},
		lg: {
			px: 20,
			h: 12
		},
		xl: {
			px: 28,
			h: 12
		}
	},
	variants: {
		primary: {
			bg: "primary.teal",
			color: "white",
			border: "1px solid transparent",
			_hover: {
				bg: "transparent",
				border: "1px solid #007091",
				color: "primary.teal"
			},
			transition:
				"color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out",
			_active: {
				bg: "primary.grey"
			}
		},
		secondary: {
			bg: "white",
			color: "primary.teal",
			_hover: {
				bg: "gray.100"
			},
			transition: "background-color 0.3s ease-in-out",
			_active: {
				bg: "white"
			}
		},
		tertiary: {
			bg: "tertiary.grey",
			color: "primary.teal",
			_hover: {
				bg: "primary.grey"
			},
			transition: "background-color 0.3s ease-in-out",
			_active: {
				bg: "tertiary.grey"
			}
		},
		link: {
			color: "gray.500",
			fontSize: "md",
			fontWeight: "normal"
		},
		outline: {
			color: "#007091",
			border: "1px solid #007091",
			_hover: {
				bg: "primary.teal",
				color: "white",
				border: "1px solid transparent"
			},
			transition:
				"color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out",
			_active: {
				bg: "tertiary.teal"
			}
		},
		submit: {
			bg: "primary.teal",
			color: "white",
			borderRadius: "3px"
		}
	},
	defaultProps: {
		size: "md",
		variant: "link",
		colorScheme: "primary.teal"
	}
});
