import { Container, Flex, Image, Text } from "@chakra-ui/react";
import {
	StoryblokComponent,
	renderRichText,
	storyblokEditable
} from "@storyblok/react";

const BannerSection = ({ blok }) => {
	const { textPosition, text, backgroundImage, ctas } = blok;

	return (
		<Flex
			{...storyblokEditable(blok)}
			align="center"
			justify="center"
			position="relative"
			flexDirection="column"
			color="white"
			py={{ base: 16, lg: 24 }}
		>
			{backgroundImage?.filename && (
				<Image
					src={backgroundImage.filename}
					alt={backgroundImage.alt}
					position="absolute"
					w="100%"
					h="100%"
					objectFit="cover"
				/>
			)}

			<Container
				maxW={{ base: "sm", lg: "7xl" }}
				mx="auto"
				position="relative"
			>
				{text && (
					<Text
						textAlign={textPosition}
						color="white"
						dangerouslySetInnerHTML={{
							__html: renderRichText(text)
						}}
					/>
				)}

				<Flex mt={2} justify="center">
					{ctas &&
						ctas.length > 0 &&
						ctas.map((cta) => (
							<StoryblokComponent
								key={cta._uid}
								blok={cta}
								styles={{
									mt: 10
								}}
							/>
						))}
				</Flex>
			</Container>
		</Flex>
	);
};

export default BannerSection;
