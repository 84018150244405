import {
	Box,
	Container,
	Flex,
	Grid,
	GridItem,
	Image,
	Text
} from "@chakra-ui/react";
import { Divider } from "@saas-ui/react";
import {
	StoryblokComponent,
	renderRichText,
	storyblokEditable
} from "@storyblok/react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const TimelineSection = ({ blok }) => {
	const { text, timeline, backgroundImage } = blok;
	return (
		<Flex
			{...storyblokEditable(blok)}
			align="center"
			justify="center"
			flexDirection="column"
			position="relative"
			py={20}
			mb={{ base: 32, lg: 0 }}
		>
			{backgroundImage?.filename && (
				<Image
					display={{ base: "none", lg: "block" }}
					src={backgroundImage.filename}
					alt={backgroundImage.alt}
					position="absolute"
					w="100%"
					h="100%"
					objectFit="cover"
				/>
			)}

			<Container
				mt={{ base: -16, lg: 0 }}
				maxW={{ base: "sm", lg: "7xl" }}
				mx="auto"
				position="relative"
			>
				{text && (
					<Text
						mb={10}
						textAlign="center"
						dangerouslySetInnerHTML={{
							__html: renderRichText(text)
						}}
					/>
				)}

				<Box display={{ base: "none", lg: "grid" }}>
					<Grid
						templateColumns={`repeat(${timeline.length}, 1fr)`}
						mb={5}
					>
						{timeline &&
							timeline.length > 0 &&
							timeline.map((item, idx) => (
								<GridItem key={item._uid} position="relative">
									<Divider
										borderBottom="2px dashed"
										borderColor="primary.teal"
										position="absolute"
										top="50%"
										left={idx === 0 ? "50%" : "0%"}
										w={
											idx === 0 ||
											idx === timeline.length - 1
												? "50%"
												: "100%"
										}
									/>
									<Box
										w={4}
										h={4}
										borderRadius="full"
										backgroundColor="primary.teal"
										mx="auto"
									/>
								</GridItem>
							))}
					</Grid>

					<Grid templateColumns={`repeat(${timeline.length}, 1fr)`}>
						{timeline &&
							timeline.length > 0 &&
							timeline.map((item) => (
								<GridItem key={item._uid}>
									<StoryblokComponent blok={item} />
								</GridItem>
							))}
					</Grid>
				</Box>
			</Container>

			<Box
				display={{ base: "block", lg: "none" }}
				position="absolute"
				w="100%"
				top="60%"
				cursor="pointer"
			>
				<Swiper
					modules={[Pagination]}
					pagination={{ clickable: true }}
					spaceBetween={50}
					slidesPerView={1}
					onSwiper={(swiper) => console.log(swiper)}
					onSlideChange={() => console.log("slide change")}
					style={{ paddingBottom: "3rem" }}
				>
					{timeline &&
						timeline.length > 0 &&
						timeline.map((item) => (
							<SwiperSlide key={item._uid}>
								<Box position="relative" mb={8}>
									<Divider
										borderBottom="2px dashed"
										borderColor="primary.teal"
										position="absolute"
										top="50%"
									/>
									<Box
										w={4}
										h={4}
										borderRadius="full"
										backgroundColor="primary.teal"
										mx="auto"
										mb={8}
									/>
								</Box>
								<StoryblokComponent blok={item} />
							</SwiperSlide>
						))}
				</Swiper>
			</Box>
			<style>{`
				.swiper-pagination {
					padding-bottom: -3rem;
				}
				.swiper-pagination-bullet-active {
					background: #007091;
				}
			`}</style>
		</Flex>
	);
};

export default TimelineSection;
