import { Box, Container, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { Card, CardBody } from "@saas-ui/react";
import {
	StoryblokComponent,
	renderRichText,
	storyblokEditable
} from "@storyblok/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Element, scroller } from "react-scroll";

const GridSection = ({
	blok,
	setCurrentFormIndex,
	setAccordionIndex,
	styles,
	setIsSubmitted2
}) => {
	const {
		textPosition,
		heading,
		text,
		columnsPerRow,
		gridGap,
		columns,
		form,
		backgroundColor,
		ctas,
		paddingTop,
		paddingBottom
	} = blok;

	var scroll = scroller;
	const location = useLocation();
	const state = location?.state;

	const [isPricingFormOpen, setIsPricingFormOpen] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState("");

	useEffect(() => {
		if (state?.autoScroll && state?.pathname === "/data-products/edgar-pro")
			scroll.scrollTo("edgar-pro-pricing", {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuint",
				offset: -120
			});
	}, [state, scroll]);

	useEffect(() => {
		setIsSubmitted("");
	}, [location]);

	return (
		<Flex
			{...storyblokEditable(blok)}
			bg={backgroundColor}
			pt={{ base: paddingTop / 2, lg: paddingTop }}
			pb={{ base: paddingBottom / 2, lg: paddingBottom }}
			{...styles}
			h="100%"
			flexDirection="column"
			justify="between"
			align="center"
		>
			<Container maxW={{ base: "sm", lg: "7xl" }} mx="auto" h="100%">
				<Element name="edgar-pro-pricing" style={{ height: "100%" }}>
					<Flex
						flexDirection="column"
						justify="space-between"
						h="100%"
					>
						{heading && (
							<Text
								mb={4}
								textAlign={{ base: "center", lg: textPosition }}
								dangerouslySetInnerHTML={{
									__html: renderRichText(heading)
								}}
							/>
						)}

						{text && (
							<Text
								mb={16}
								textAlign={{ base: "left", lg: textPosition }}
								dangerouslySetInnerHTML={{
									__html: renderRichText(text)
								}}
							/>
						)}

						{!isPricingFormOpen && columnsPerRow && (
							<Grid
								templateColumns={{
									base: "repeat(1,1fr)",
									lg: `repeat(${columnsPerRow}, 1fr)`
								}}
								gap={gridGap}
								w="100%"
							>
								{columns &&
									columns.length > 0 &&
									columns.map((blok) => (
										<GridItem key={blok.content._uid}>
											<StoryblokComponent
												blok={blok.content}
												name={blok.name}
												setCurrentFormIndex={
													setCurrentFormIndex
												}
												setIsPricingFormOpen={
													setIsPricingFormOpen
												}
												setAccordionIndex={
													setAccordionIndex
												}
												setIsSubmitted={setIsSubmitted2}
											/>
										</GridItem>
									))}
							</Grid>
						)}

						{isPricingFormOpen &&
							isSubmitted !== form?.content?.leadSource &&
							form?.content && (
								<Card
									borderWidth="1px"
									borderStyle="solid"
									borderColor="primary.teal"
									p={{ base: 0, lg: 8 }}
									position="relative"
								>
									<Box
										position="absolute"
										top={{ base: 0, lg: 8 }}
										right={0}
									>
										<StoryblokComponent
											blok={form.content.backButton[0]}
											onClick={() =>
												setIsPricingFormOpen(false)
											}
										/>
									</Box>
									<CardBody w="100%" maxW="4xl" mx="auto">
										<StoryblokComponent
											blok={form.content}
											setIsSubmitted={setIsSubmitted}
											isPricingFormOpen={
												isPricingFormOpen
											}
										/>
									</CardBody>
								</Card>
							)}

						{isSubmitted === form?.content?.leadSource &&
							form?.content?.submitBanner && (
								<StoryblokComponent
									blok={form.content.submitBanner.content}
								/>
							)}

						{ctas &&
							ctas.length > 0 &&
							ctas.map((cta) => {
								return (
									<Flex
										key={cta._uid}
										justify="center"
										mt={20}
									>
										<StoryblokComponent blok={cta} />
									</Flex>
								);
							})}
					</Flex>
				</Element>
			</Container>
		</Flex>
	);
};

export default GridSection;
