import { Accordion, Container, Flex, Text } from "@chakra-ui/react";
import {
	StoryblokComponent,
	renderRichText,
	storyblokEditable
} from "@storyblok/react";
import { useLocation } from "react-router-dom";

const FAQSection = ({ blok }) => {
	const location = useLocation();
	const isFaqPage = location.pathname === "/company/faq";
	const { textPosition, heading, text, faqs, ctas } = blok;

	return (
		<Flex
			{...storyblokEditable(blok)}
			py={{ base: 10, lg: 20 }}
			flexDirection="column"
			justify="center"
			align="center"
		>
			<Container maxW={{ base: "sm", lg: "7xl" }} mx="auto">
				{heading && (
					<Text
						mb={4}
						textAlign={{ base: "center", lg: textPosition }}
						dangerouslySetInnerHTML={{
							__html: renderRichText(heading)
						}}
					/>
				)}

				{text && (
					<Text
						mb={12}
						textAlign={{ base: "left", lg: textPosition }}
						dangerouslySetInnerHTML={{
							__html: renderRichText(text)
						}}
					/>
				)}

				{faqs && (
					<Accordion allowToggle mb={12}>
						{faqs
							.slice(0, isFaqPage ? faqs.length : 4)
							.map((blok) => (
								<StoryblokComponent
									blok={blok.content}
									key={blok.content._uid}
								/>
							))}
					</Accordion>
				)}

				<Flex justify="center">
					{ctas &&
						ctas.length > 0 &&
						ctas.map((cta) => {
							return (
								<StoryblokComponent blok={cta} key={cta._uid} />
							);
						})}
				</Flex>
			</Container>
		</Flex>
	);
};

export default FAQSection;
