import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { Card, CardBody } from "@saas-ui/react";
import {
	renderRichText,
	storyblokEditable,
	useStoryblokState
} from "@storyblok/react";

const WhoUsesUsCard = ({ story: initialStory, blok }) => {
	const story = useStoryblokState(initialStory);
	const { heading, text, images, alignment } = story?.content || blok;

	return (
		<Card
			{...storyblokEditable(blok)}
			p={6}
			h="100%"
			border="1px solid #007091"
			borderRadius="lg"
			_hover={{ boxShadow: "xl" }}
			transition="box-shadow 0.4s"
		>
			<CardBody>
				<Flex direction="column" align={alignment} justify="center">
					{images[0]?.filename && (
						<Box>
							<Image
								src={images[0].filename}
								alt={images[0].alt}
								mb={10}
							/>
						</Box>
					)}

					{heading && (
						<Heading as="h2" color="primary.teal">
							{heading}
						</Heading>
					)}

					{text && (
						<Text
							mt={6}
							textAlign={alignment}
							dangerouslySetInnerHTML={{
								__html: renderRichText(text)
							}}
						/>
					)}
				</Flex>
			</CardBody>
		</Card>
	);
};

export default WhoUsesUsCard;
