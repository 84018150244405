import { cloneDeep } from "@apollo/client/utilities";
import { Td, Tr } from "@chakra-ui/react";
import {
	RichTextSchema,
	renderRichText,
	storyblokEditable
} from "@storyblok/react";
import React from "react";

const mySchema = cloneDeep(RichTextSchema);

const TableRow = ({ blok }) => {
	const { label, value } = blok;

	const labelCellStyles = {
		bg: "primary.teal",
		color: "white",
		textTransform: "uppercase",
		fontWeight: "bold",
		fontSize: "xl"
	};

	return (
		<Tr
			{...storyblokEditable(blok)}
			_hover={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}
			transition="box-shadow 0.3s"
			whiteSpace="wrap"
		>
			{label && <Td {...labelCellStyles}>{label}</Td>}
			{value && (
				<Td
					position="relative"
					dangerouslySetInnerHTML={{
						__html: renderRichText(value, {
							schema: mySchema,
							resolver: (component, blok) => {
								switch (component) {
									case "link":
										const link =
											blok?.link?.cached_url ||
											blok?.link?.url;
										if (
											link?.includes(".pdf") ||
											link?.includes(".txt") ||
											link?.includes(".xml") ||
											link?.includes(".xlsx")
										) {
											return `                       
                        <a href="${link}" target="_blank" rel="noopener noreferrer">
                          ${renderRichText(blok.label)}                         
                        </a>                                    
                 `;
										}
										break;
									default:
										return `Component ${component} not found`;
								}
							}
						})
					}}
				/>
			)}
		</Tr>
	);
};

export default TableRow;
