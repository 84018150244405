import {
	Box,
	Container,
	Flex,
	Grid,
	GridItem,
	Image,
	Text
} from "@chakra-ui/react";
import {
	StoryblokComponent,
	renderRichText,
	storyblokEditable,
	useStoryblokState
} from "@storyblok/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Footer = ({ story: initialStory, blok, showTopFooter = true }) => {
	const location = useLocation();
	const story = useStoryblokState(initialStory);
	const {
		topFooterBackgroundImage,
		topFooterHeading,
		topFooterText,
		form,
		bottomFooterBackgroundImage,
		bottomFooterImage,
		bottomFooterLinks,
		bottomFooterText1,
		bottomFooterText2,
		submitBanner
	} = story?.content || blok;

	const [isSubmitted, setIsSubmitted] = useState("");

	useEffect(() => {
		setIsSubmitted("");
	}, [location]);

	return (
		<Flex
			{...storyblokEditable(blok)}
			flexDirection="column"
			justify="center"
			align="center"
		>
			{showTopFooter && (
				<Flex
					bg="primary.teal"
					w="full"
					py={{ base: 10, lg: 20 }}
					align="center"
					justify="center"
					position="relative"
					flexDirection="column"
				>
					{topFooterBackgroundImage.filename && (
						<Image
							src={topFooterBackgroundImage.filename}
							alt={topFooterBackgroundImage.alt}
							position="absolute"
							objectFit="cover"
							display={{ base: "none", lg: "block" }}
						/>
					)}

					<Container maxW={{ base: "sm", lg: "5xl" }} mx="auto">
						<Flex
							flexDirection="column"
							justify="center"
							align="center"
							color="white"
							bg="primary.teal"
						>
							{topFooterHeading && (
								<Text
									textAlign="center"
									mb={4}
									dangerouslySetInnerHTML={{
										__html: renderRichText(topFooterHeading)
									}}
								/>
							)}

							{topFooterText && (
								<Text
									textAlign={{ base: "left", lg: "center" }}
									mb={8}
									dangerouslySetInnerHTML={{
										__html: renderRichText(topFooterText)
									}}
								/>
							)}

							{isSubmitted !== form?.content?.leadSource &&
								form &&
								form?.content && (
									<StoryblokComponent
										key={form.content._uid}
										blok={form.content}
										setIsSubmitted={setIsSubmitted}
									/>
								)}

							<Box w="100%">
								{isSubmitted === form?.content?.leadSource &&
									submitBanner && (
										<StoryblokComponent
											blok={submitBanner?.content}
										/>
									)}
							</Box>
						</Flex>
					</Container>
				</Flex>
			)}

			<Flex
				w="full"
				py={16}
				align="center"
				justify="center"
				flexDirection="column"
				position="relative"
			>
				{bottomFooterBackgroundImage.filename && (
					<Image
						src={bottomFooterBackgroundImage.filename}
						alt={bottomFooterBackgroundImage.alt}
						position="absolute"
						top="0"
						objectFit="cover"
					/>
				)}

				<Container
					maxW={{ base: "sm", lg: "7xl" }}
					mx="auto"
					position="relative"
				>
					{bottomFooterImage &&
						bottomFooterImage.length > 0 &&
						bottomFooterImage.map((item) => (
							<Flex
								key={item._uid}
								mb={8}
								justify="center"
								display={{ base: "flex", lg: "none" }}
							>
								<StoryblokComponent blok={item} />
							</Flex>
						))}

					<Grid
						templateColumns="repeat(5,1fr)"
						gap={8}
						alignItems="center"
					>
						<GridItem
							display={{ base: "none", lg: "block" }}
							colSpan={2}
						>
							{bottomFooterImage &&
								bottomFooterImage.length > 0 &&
								bottomFooterImage.map((item) => (
									<Box key={item._uid} mb={8}>
										<StoryblokComponent blok={item} />
									</Box>
								))}

							{bottomFooterText1 && (
								<Text
									mb={8}
									dangerouslySetInnerHTML={{
										__html: renderRichText(
											bottomFooterText1
										)
									}}
								/>
							)}
						</GridItem>

						<GridItem colSpan={{ base: 5, lg: 3 }}>
							<Grid
								templateColumns={{
									base: "repeat(2,1fr)",
									lg: `repeat(3,1fr)`
								}}
							>
								{bottomFooterLinks &&
									bottomFooterLinks.length &&
									bottomFooterLinks.map((link) => {
										return (
											<GridItem key={link._uid}>
												<StoryblokComponent
													blok={link}
													styles={{
														mb: 4
													}}
												/>
											</GridItem>
										);
									})}
							</Grid>
						</GridItem>
					</Grid>

					{bottomFooterText1 && (
						<Text
							my={10}
							w="100%"
							display={{ base: "block", lg: "none" }}
							dangerouslySetInnerHTML={{
								__html: renderRichText(bottomFooterText1)
							}}
						/>
					)}

					{bottomFooterText2 && (
						<Text
							maxW={{ base: "full", lg: "3xl" }}
							mt={{ base: 8, lg: 0 }}
							color="gray.500"
							fontSize="sm"
							dangerouslySetInnerHTML={{
								__html: renderRichText(bottomFooterText2)
							}}
						/>
					)}
				</Container>
			</Flex>
		</Flex>
	);
};

export default Footer;
