import { cloneDeep } from "@apollo/client/utilities";
import { Container, Flex, Text } from "@chakra-ui/react";
import { Card, CardBody } from "@saas-ui/react";
import {
	storyblokEditable,
	renderRichText,
	StoryblokComponent,
	RichTextSchema,
	useStoryblokState
} from "@storyblok/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { scroller, Element } from "react-scroll";

const mySchema = cloneDeep(RichTextSchema);

const TextOnlySection = ({ story: initialStory, blok }) => {
	const story = useStoryblokState(initialStory);
	const {
		heading,
		text,
		text2,
		form,
		backgroundColor,
		textPosition,
		paddingTop,
		paddingBottom,
		isSubmitBanner
	} = story?.content || blok;

	var scroll = scroller;
	const location = useLocation();
	const state = location?.state;

	const [isSubmitted, setIsSubmitted] = useState("");

	useEffect(() => {
		if (state?.autoScroll && state?.pathname !== "/data-products/edgar-pro")
			scroll.scrollTo("api-pricing", {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuint",
				offset: -120
			});
	}, [state, scroll]);

	useEffect(() => {
		setIsSubmitted("");
	}, [location]);

	return (
		<Flex
			{...storyblokEditable(blok)}
			bg={backgroundColor}
			// py={{ base: 16, lg: 32 }}
			pt={{ base: paddingTop / 2, lg: paddingTop }}
			pb={{ base: paddingBottom / 2, lg: paddingBottom }}
			flexDirection="column"
			justify="center"
			align="center"
			borderRadius={isSubmitBanner ? "xl" : "none"}
		>
			<Container maxW={{ base: "sm", lg: "7xl" }} mx="auto">
				<Element name="api-pricing">
					{heading && (
						<Text
							mb={4}
							textAlign={{ base: "center", lg: textPosition }}
							dangerouslySetInnerHTML={{
								__html: renderRichText(heading)
							}}
						/>
					)}

					{text && (
						<Text
							textAlign={textPosition}
							dangerouslySetInnerHTML={{
								__html: renderRichText(text, {
									schema: mySchema,
									resolver: (component, blok) => {
										switch (component) {
											case "link":
												return `
                        <a href="${
							blok.link.cached_url || blok.link.url
						}" target="${blok.target}" rel="${
													blok.target === "_blank"
														? "noopener noreferrer"
														: ""
												}">
                      <div style="display:flex; align-items:center; margin-bottom: 1rem">
                      ${
							blok?.image?.filename
								? `<img
									style="margin-right: 0.5rem"
									width="30px"
									height="auto"
									src="${blok.image.filename}"
									alt="${blok.image.alt}"
								/>`
								: ""
						}

            ${
				blok?.link?.cached_url?.includes(".pdf") ||
				blok?.link?.cached_url?.includes(".txt") ||
				blok?.link?.cached_url?.includes(".xml") ||
				blok?.link?.cached_url?.includes("gaoptout")
					? `                       
                  <a href="${
						blok?.link?.cached_url
					}" target="_blank" rel="noopener noreferrer">
                    ${renderRichText(blok.label)}                         
                  </a>`
					: ` ${renderRichText(blok.label)}   `
			}
                        
            
                   </div>   
                      `;

											default:
												return `Component ${component} not found`;
										}
									}
								})
							}}
						/>
					)}

					{isSubmitted !== form?.content?.leadSource &&
						form?.content && (
							<Card
								borderWidth="1px"
								borderStyle="solid"
								borderColor="primary.teal"
								p={{ base: 0, lg: 8 }}
							>
								<CardBody w="100%" maxW="4xl" mx="auto">
									<StoryblokComponent
										blok={form.content}
										setIsSubmitted={setIsSubmitted}
									/>
								</CardBody>
							</Card>
						)}

					{text2 && isSubmitted !== form?.content?.leadSource && (
						<Text
							mt={8}
							textAlign={textPosition}
							dangerouslySetInnerHTML={{
								__html: renderRichText(text2)
							}}
						/>
					)}

					{isSubmitted === form?.content?.leadSource &&
						form?.content?.submitBanner && (
							<StoryblokComponent
								blok={form.content.submitBanner.content}
							/>
						)}
				</Element>
			</Container>
		</Flex>
	);
};

export default TextOnlySection;
