import { Box, Flex, Text } from "@chakra-ui/react";
import { Card, CardBody } from "@saas-ui/react";
import {
	StoryblokComponent,
	renderRichText,
	storyblokEditable,
	useStoryblokState
} from "@storyblok/react";
import { useLocation } from "react-router-dom";

const PricingPlan = ({
	story: initialStory,
	blok,
	name,
	setIsPricingFormOpen,
	setCurrentFormIndex,
	setAccordionIndex,
	setIsSubmitted
}) => {
	const story = useStoryblokState(initialStory);
	const { text, ctas } = story?.content || blok;

	const location = useLocation();
	const isHomepage =
		location.pathname === "/" || location.pathname === "/home";

	return (
		<>
			<Card
				{...storyblokEditable(blok)}
				position="relative"
				rounded="xl"
				borderWidth="1px"
				borderStyle="solid"
				borderColor="primary.teal"
				p={{ base: 0, lg: 6 }}
				h="100%"
				minH={{ lg: "350px" }}
			>
				<CardBody h="100%">
					<Flex
						flexDirection="column"
						justify={{ lg: "space-between" }}
						align="center"
						h="100%"
					>
						{text && (
							<Text
								fontSize="md"
								mb={5}
								textAlign="center"
								dangerouslySetInnerHTML={{
									__html: renderRichText(text)
								}}
							/>
						)}

						{ctas &&
							ctas.length > 0 &&
							ctas.map((cta) => {
								return (
									<Box
										key={cta._uid}
										textAlign="center"
										position={{
											lg:
												cta?.component === "link" &&
												"absolute"
										}}
										bottom={cta?.component === "link" && 0}
									>
										{isHomepage ? (
											<StoryblokComponent
												blok={cta}
												onClick={() => {
													if (setAccordionIndex) {
														setAccordionIndex(0);

														if (setIsSubmitted) {
															setIsSubmitted("");
														}
													}
													if (setCurrentFormIndex) {
														setCurrentFormIndex(
															name ===
																"API and File delivery"
																? 0
																: 1
														);

														if (setIsSubmitted) {
															setIsSubmitted("");
														}
													}
												}}
												styles={{
													mb: 4
												}}
											/>
										) : (
											<StoryblokComponent
												blok={cta}
												styles={{
													mb: 4
												}}
												onClick={() => {
													if (setIsPricingFormOpen) {
														setIsPricingFormOpen(
															name
														);
													}
												}}
											/>
										)}
									</Box>
								);
							})}
					</Flex>
				</CardBody>
			</Card>
		</>
	);
};

export default PricingPlan;
