import { TableContainer, Table, Tbody } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import React from "react";

const StoryblokTable = ({ blok }) => {
	const { rows } = blok;

	return (
		<TableContainer
			{...storyblokEditable(blok)}
			maxW={{ base: "sm", lg: "6xl" }}
			mx="auto"
			overflowX="hidden"
			border="1px solid"
			borderColor="gray.200"
			borderRadius="md"
		>
			<Table size={{ base: "sm", lg: "md" }} variant="simple">
				<Tbody>
					{rows &&
						rows.length > 0 &&
						rows.map((blok) => (
							<StoryblokComponent key={blok._uid} blok={blok} />
						))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};

export default StoryblokTable;
