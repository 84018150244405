import { Form, FormLayout } from "@saas-ui/react";
import {
	StoryblokComponent,
	storyblokEditable,
	useStoryblokState
} from "@storyblok/react";
import { Box, Flex } from "@chakra-ui/react";
import axios from "../../api/axios";

function splitFullName(fullName) {
	const indexFirstSpace = fullName.indexOf(" ");
	if (indexFirstSpace === -1) {
		return [fullName, ""];
	} else {
		const firstName = fullName.substring(0, indexFirstSpace);
		const lastName = fullName.substring(indexFirstSpace + 1);
		return [firstName, lastName];
	}
}

const FormSection = ({
	story: initialStory,
	blok,
	setIsSubmitted,
	isPricingFormOpen
}) => {
	const story = useStoryblokState(initialStory);
	const { leadSource, columns, ctas, ctaPosition } = story?.content || blok;

	const onSubmit = async (params) => {
		let parameters;
		if (
			leadSource &&
			leadSource?.length > 0 &&
			leadSource === "Website Footer Form"
		) {
			const [firstName, lastName] = splitFullName(params?.first_name);
			parameters = {
				...params,
				first_name: firstName,
				last_name: lastName,
				lead_source: leadSource
			};
		} else if (
			leadSource &&
			leadSource?.length > 0 &&
			leadSource === "Website Edgar Pro Pricing Form"
		) {
			parameters = {
				...params,
				"00N1S000007YXRx":
					params["00N1S000007YXRx"]?.length > 1
						? "Both"
						: params["00N1S000007YXRx"][0],
				lead_source: leadSource
			};
		} else {
			parameters = { ...params, lead_source: leadSource };
		}

		if (setIsSubmitted) setIsSubmitted(leadSource);
		await axios.post("/api/webToLead", parameters);
	};

	return (
		<Form
			id={leadSource}
			{...storyblokEditable(blok)}
			onSubmit={onSubmit}
			w="100%"
		>
			<FormLayout
				columns={{ base: 1, lg: columns && columns?.length }}
				gap={10}
			>
				{columns &&
					columns.length > 0 &&
					columns.map((column, idx) => (
						<FormLayout
							key={column._uid}
							gap={8}
							mt={{
								lg:
									leadSource ===
										"Website Edgar Pro Pricing Form" &&
									idx === 1 &&
									"86px"
							}}
						>
							{column.items &&
								column.items.length > 0 &&
								column.items.map((item) => (
									<StoryblokComponent
										key={item._uid}
										blok={item}
										isPricingFormOpen={isPricingFormOpen}
									/>
								))}
						</FormLayout>
					))}
			</FormLayout>

			<Flex direction="column" align={ctaPosition} flex={1}>
				{ctas &&
					ctas.length > 0 &&
					ctas.map((button) => (
						<Box key={button._uid} mt={8}>
							<StoryblokComponent
								blok={button}
								colorScheme="white"
							/>
						</Box>
					))}
			</Flex>
		</Form>
	);
};

export default FormSection;
