import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { extendTheme } from "@chakra-ui/react";
import { SaasProvider, theme as baseTheme } from "@saas-ui/react";

import { storyblokInit, apiPlugin } from "@storyblok/react";

import Page from "./components/Page";
import GridSection from "./components/section/GridSection";
import Header from "./components/section/Header";
import TextWithImageSection from "./components/section/TextWithImageSection";
import HeroSection from "./components/section/HeroSection";
import FAQSection from "./components/section/FAQSection";
import FAQ from "./components/common/FAQ";
import StoryblokButton from "./components/common/StoryblokButton";
import StoryblokLink from "./components/common/StoryblokLink";
import Footer from "./components/section/Footer";
import FormSection from "./components/form/FormSection";
import FormInput from "./components/form/FormInput";
import { Global, css } from "@emotion/react";
import BannerSection from "./components/section/BannerSection";
import TimelineSection from "./components/section/TimelineSection";
import TimelineItem from "./components/common/TimelineItem";
import FigureSection from "./components/section/FigureSection";
import Figure from "./components/common/Figure";
import TableSection from "./components/section/TableSection";
import PricingPlan from "./components/common/PricingPlan";
import TableRow from "./components/table/TableRow";
import StoryblokTable from "./components/table/StoryblokTable";
import TextOnlySection from "./components/section/TextOnlySection";
import DataProductCard from "./components/common/DataProductCard";
import MultiColumnSection from "./components/section/MultiColumnSection";
import WhoUsesUsCard from "./components/common/WhoUsesUsCard";
import { buttonTheme } from "./theme/Button";
import axios from "./api/axios";

const getAccessToken = async () => {
	const token = await axios.get("/api/getAccessToken");
	return token.data;
};

storyblokInit({
	accessToken: getAccessToken(),
	use: [apiPlugin],
	apiOptions: {
		region: "us",
		cache: { type: "memory" }
	},
	components: {
		page: Page,
		header: Header,
		heroSection: HeroSection,
		bannerSection: BannerSection,
		gridSection: GridSection,
		multiColumnSection: MultiColumnSection,
		dataProductCard: DataProductCard,
		whoUsesUsCard: WhoUsesUsCard,
		pricingPlan: PricingPlan,
		textOnlySection: TextOnlySection,
		textWithImageSection: TextWithImageSection,
		tableSection: TableSection,
		table: StoryblokTable,
		tableRow: TableRow,
		faqSection: FAQSection,
		faq: FAQ,
		formSection: FormSection,
		formInput: FormInput,
		timelineSection: TimelineSection,
		timelineItem: TimelineItem,
		figureSection: FigureSection,
		figure: Figure,
		footer: Footer,
		button: StoryblokButton,
		link: StoryblokLink
	}
});

const globalStyle = css`
	@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&family=Lato:wght@100;200;300;400;500;600;700;800;900&display=swap");
`;

const theme = extendTheme(
	{
		colors: {
			primary: {
				teal: "#007091",
				grey: "#EEF4F6"
			},
			secondary: {
				ink: "#172126"
			},
			tertiary: {
				teal: "#048DB6",
				grey: "#F5F5F5"
			}
		},
		fonts: {
			heading: "Jost, sans-serif",
			body: "Lato, sans-serif"
		},
		components: {
			Button: buttonTheme
		},
		styles: {
			global: {
				body: {
					bg: "primary.grey",
					color: "secondary.ink"
				},
				h1: {
					fontSize: { base: "3xl", lg: "5xl" },
					fontWeight: "bold",
					fontFamily: "heading"
				},
				h2: {
					fontSize: "4xl",
					fontWeight: "bold",
					fontFamily: "heading"
				},
				h3: {
					fontSize: "3xl",
					fontWeight: "bold",
					fontFamily: "heading"
				},
				h4: {
					fontSize: "xl",
					fontWeight: "semibold",
					fontFamily: "heading"
				},
				ul: {
					marginLeft: "1rem"
				},
				li: {
					margin: "0.75rem auto"
				}
			}
		}
	},
	baseTheme
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<SaasProvider theme={theme}>
			<Global styles={globalStyle} />
			<App />
		</SaasProvider>
	</React.StrictMode>
);
