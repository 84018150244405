import { MinusIcon } from "@chakra-ui/icons";
import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Card,
	CardBody,
	Container,
	Flex,
	Grid,
	GridItem
} from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Element, scroller } from "react-scroll";

const MultiColumnSection = ({ blok }) => {
	const { columnsPerRow, columns, backgroundColor, forms } = blok;
	const location = useLocation();

	const [currentFormIndex, setCurrentFormIndex] = useState(0);
	const [isSubmitted, setIsSubmitted] = useState("");

	const [accordionIndex, setAccordionIndex] = useState(-1);
	var scroll = scroller;

	useEffect(() => {
		if (accordionIndex === 0)
			scroll.scrollTo("edgar-pro-pricing", {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuint",
				offset: 580
			});
	}, [accordionIndex, scroll]);

	useEffect(() => {
		setIsSubmitted("");
	}, [location]);

	return (
		<Flex
			{...storyblokEditable(blok)}
			bg={backgroundColor}
			flexDirection="column"
			justify="center"
			align="center"
		>
			<Container maxW={{ base: "sm", lg: "7xl" }} mx="auto">
				<Accordion allowToggle index={accordionIndex}>
					<AccordionItem border="none">
						{columnsPerRow && (
							<Grid
								templateColumns={{
									base: "repeat(1,1fr)",
									lg: `repeat(${columnsPerRow}, 1fr)`
								}}
								gap={8}
								w="100%"
							>
								{columns &&
									columns.length > 0 &&
									columns.map((blok) => (
										<GridItem key={blok._uid}>
											<StoryblokComponent
												blok={blok}
												setAccordionIndex={
													setAccordionIndex
												}
												setCurrentFormIndex={
													setCurrentFormIndex
												}
												setIsSubmitted2={setIsSubmitted}
											/>
										</GridItem>
									))}
							</Grid>
						)}
						<AccordionPanel pb={{ base: 16, lg: 32 }}>
							{isSubmitted !==
								forms[currentFormIndex]?.content
									?.leadSource && (
								<Card
									borderWidth="1px"
									borderStyle="solid"
									borderColor="primary.teal"
									position="relative"
									p={{ base: 0, lg: 8 }}
								>
									<AccordionButton
										_hover={{
											bg: "none"
										}}
										onClick={() => {
											setAccordionIndex(-1);
										}}
									>
										<Flex
											bg="white"
											position="absolute"
											top={4}
											right={4}
											p={3}
											w={8}
											h={8}
											justify="center"
											align="center"
											rounded="full"
											border="1px solid black"
										>
											<MinusIcon />
										</Flex>
									</AccordionButton>
									<CardBody w="100%" maxW="4xl" mx="auto">
										{forms && forms.length > 0 && (
											<Element name="form">
												<StoryblokComponent
													blok={
														forms[currentFormIndex]
															?.content
													}
													setIsSubmitted={
														setIsSubmitted
													}
													isPricingFormOpen="Edgar Pro"
												/>
											</Element>
										)}
									</CardBody>
								</Card>
							)}

							{isSubmitted ===
								forms[currentFormIndex]?.content?.leadSource &&
								forms[currentFormIndex]?.content
									?.submitBanner && (
									<StoryblokComponent
										blok={
											forms[currentFormIndex].content
												.submitBanner.content
										}
									/>
								)}
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			</Container>
		</Flex>
	);
};

export default MultiColumnSection;
